<template>
    <div style="border-radius: 8px" class="row journal-line">
        <div class="col-12 d-flex product-details-border position-relative pe-0">
            <div class="w-100 pe-lg-0 me-1 py-2">
                <div class="row">
                    <div class="cols-12 col-sm-6 col-lg-4 col-xl-3 mt-2">
                        <label for="colFormLabel" class="col-form-label">Project</label>
                        <v-select
                            placeholder="Select Project"
                            v-model="selectedProject"
                            :options="projects"
                            label="name"
                            :reduce="name => name.id"
                            @option:selected="onChangeSelectedProject"
                        />
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xl-3 mt-2">
                        <label for="colFormLabel" class="col-form-label">Budget</label>
                        <AsyncSelect
                            placeholder="Select Budget"
                            v-model="selectedBudgetMaster"
                            :api-service="fetchProjectDonors"
                            :url-param="selectedProject"
                            ref="budgetMasterDropdown"
                            :option-selected="onChangeSelectedBudget"
                        />
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xl-3 mt-2">
                        <label for="colFormLabel" class="col-form-label">
                          Activity Head (<a href="#" @click.prevent="showActualDataModal()">{{ commaFormat(budget) }}</a>)
                        </label>
                        <AsyncSelect
                            placeholder="Select Budget"
                            v-model="selectedActivity"
                            :api-service="fetchDonorProjectAccounts"
                            :url-param="item.budget_master_id"
                            :additional-query="{voucher_date: paymentDate}"
                            ref="activityDropdown"
                            :option-selected="onChangeActivityHead"
                        />
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xl-3 mt-2">
                        <label for="colFormLabel" class="col-form-label">FD6 Budget</label>
                        <v-select
                            placeholder="Select FD6 Budget"
                            v-model="selectedFd6BudgetMaster"
                            :options="fd6Donors"
                            label="name"
                            :reduce="name => name.id"
                            @option:selected="onSelectFd6BudgetMaster"
                        />
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xl-3 mt-2">
                        <label for="colFormLabel" class="col-form-label">FD6 Activity</label>
                        <v-select
                            placeholder="Select FD6 Activity"
                            v-model="item.fd6_account_id"
                            :options="ngoabHeads"
                            label="name"
                            :reduce="name => name.id"
                        />
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xl-3 mt-2">
                        <label for="colFormLabel" class="col-form-label">Account Head</label>
                        <v-select
                            placeholder="Select Account Head"
                            v-model="item.account_head_id"
                            :options="accountHeads"
                            label="name"
                            :reduce="name => name.id"
                        />
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xl-3 mt-2">
                        <label for="colFormLabel" class="col-form-label">Program</label>
                        <v-select
                            placeholder="Select Program"
                            v-model="item.program_id"
                            :options="programs"
                            label="name"
                            :reduce="name => name.id"
                        />
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xl-3 mt-2">
                        <label for="colFormLabel" class="col-form-label">Cost Centre</label>
                        <v-select
                            placeholder="Select Cost Centre"
                            v-model="item.cost_centre_id"
                            :options="costCentres"
                            label="name"
                            :reduce="name => name.id"
                        />
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3 mt-2">
                      <label for="colFormLabel" class="col-form-label">Business</label>
                      <v-select
                          placeholder="Select Business"
                          class="w-100"
                          :options="businesses"
                          label="name"
                          :reduce="name => name.id"
                          v-model="item.business_id"
                      />
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xl-3 mt-2">
                      <label for="colFormLabel" class="col-form-label">
                        Party (<a href="#" @click.prevent="showPartyModal()">{{ commaFormat(partyReportDetails.closing_balance ?? 0) }}</a>)
                      </label>
                      <AsyncSelect
                          placeholder="Select Party"
                          v-model="item.party"
                          :api-service="fetchContactProfiles"
                          :format-label="formatPatientLabel"
                      />
                    </div>
                    <div class="col-md-6 col-12 col-lg-4 col-xl-3 mt-2">
                        <label class="tds-vds-label">
                            <span class="me-2">TDS VDS Compliance</span>
                        </label>

                        <button @click="$emit('onClickToApplyTdsVds')" v-if="!item.is_vat_added" type="button" class="btn btn-primary btn-sm btn-block">
                            <i class="fas fa-plus"></i>
                        </button>

                        <button v-if="item.is_vat_added" @click="$emit('onClickToApplyTdsVds')" type="button" class="btn btn-outline-primary btn-sm btn-block">
                            <i class="fas fa-pen"></i>
                        </button>

                        <div class="card mt-1 mb-0" v-if="item.is_vat_added">
                            <div class="card-body">
                                <ul class="no-dots">
                                    <li>
                                        <span>VDS / Mushak 6.3 Amount = </span>
                                        <span>{{ commaFormat(item.vds_amount) }}</span>
                                    </li>
                                    <li>
                                        <span>TDS Amount = </span>
                                        <span>{{ commaFormat(item.tds_amount) }}</span>
                                    </li>
                                    <li>
                                        <span>Total Amount = </span>
                                        <span>{{ commaFormat(item.total_amount) }}</span>
                                    </li>
                                    <li>
                                        <span>Net Payable Amount = </span>
                                        <span>{{ commaFormat(item.net_payable_amount) }}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
                </div>
                <div class="row mt-2">
                    <div class="col-lg-8 col-12 mb-lg-0 mb-2 mt-lg-0 mt-2">
                        <textarea
                            v-model="item.note"
                            placeholder="Description"
                            class="form-control mt-2"
                            rows="1"
                        >
                        </textarea>
                    </div>
                    <div class="col-lg-4 col-12 mt-2">
                        <input
                            v-model="item.debit"
                            type="number"
                            class="form-control text-end"
                            placeholder="Amount"
                            @blur="checkLimit"
                            @input="onChangeDebit"
                        >
                    </div>
                </div>
            </div>
            <div
                @click="$emit('onClose', index)"
                class="
                d-flex
                flex-column
                align-items-center
                justify-content-between
                border-start
                invoice-product-actions
                py-50
                px-25"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                     class="feather feather-x cursor-pointer font-medium-3">
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
            </div>
        </div>
        <ActualDetailsModal
          ref="actualBudgetModal"
          :activity_details="selectedActivityDetail"
          :actual_cost_details="actualCostDetails"
          :item="item"
        />
        <PartyDetailsModal
            ref="partyReportModal"
            :contact_profile_id="item.contact_profile_id"
            :partyDetails="partyReportDetails"
            :party="partyDetails"
            :date_range="reportDateRange"
        />
    </div>
</template>

<script setup>
    import {computed, inject, onMounted, ref, watch} from "vue";
    import handleProjects                  from "@/services/modules/procurement/project";
    import {useRoute}                      from "vue-router";
    import ActualDetailsModal              from "@/components/molecule/ngo/payment/ActualDetailsModal";
    import figureFormatter                 from "@/services/utils/figureFormatter";
    import handleFD6s                      from "@/services/modules/procurement/fd6";
    import PartyDetailsModal from "@/components/molecule/ngo/payment/PartyDetailsModal.vue";
    import handleReport from "@/services/modules/accountingReport";
    import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
    import handleContact from "@/services/modules/contact";
    import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";

    const showError =  inject('showError');
    const $route = useRoute();
    const $props = defineProps({
        accountHeads: {
            type: Array,
            default: () => [],
        },
        ngoabHeads: {
            type: Array,
            default: () => [],
        },
        fd6Donors: {
            type: Array,
            default: () => [],
        },
        programs: {
            type: Array,
            default: () => [],
        },
        projects: {
            type: Array,
            default: () => [],
        },
        costCentres: {
            type: Array,
            default: () => [],
        },
        contactProfiles: {
            type: Array,
            default: () => [],
        },
        businesses: {
            type: Array,
            default: () => [],
        },
        index: {
            type: Number,
            default: () => 0
        },
        item: {
            type: Object,
            default: () => ({
                account_head_id: null,
                fd6_account_id: null,
                budget_head_id: null,
                program_id: null,
                project_id: null,
                project_account_id: null,
                cost_centre_id: null,
                business_id: null,
                debit: null,
                credit: null,
                note: '',
                tax_rate: null,
                taxable_amount: null,
                donor_id: null,
                budget_master_id: null,
                budget_general_id: null,
                fd6_budget_master_id: null,
                fd6_budget_general_id: null,
                party: {},
                contact_profile_id: null,
            }),
        },
        paymentDate: {
            type: String,
            default: ''
        },
        onClickToApplyTdsVds: {
            type: Function,
            default: () => {}
        }
    });

    const {fetchProjectDonors, fetchDonorProjectAccounts, fetchActualCost} = handleProjects();
    const {fetchFd6BudgetGeneral} = handleFD6s();
    const {fetchContactProfiles} = handleContact()
    const { formatPatientLabel } = useAsyncDropdownHelper();
    const $emit = defineEmits(['isLoading','loaded','onClose', 'onClickToApplyTdsVds']);
    const {commaFormat} = figureFormatter();
    const {getPartyLedgerSummary} = handleReport();
    const donors = ref([]);
    const budgetMasterDropdown = ref(null);
    const activityDropdown = ref(null);
    const selectedProject = ref(null);
    const selectedBudgetMaster = ref(null);
    const selectedFd6BudgetMaster = ref(null);
    const selectedActivity = ref(null);
    const selectedActivityDetail = ref({});
    const selectedDonor = ref({});
    let   originalBudget = 0;
    const budget = ref(0);
    const actualBudgetModal = ref(null);
    const partyReportModal = ref(null);
    const actualCostDetails = ref({});
    const partyReportDetails = ref({});
    const partyDetails = ref({});
    const firstLoad = ref({
        budgetMaster: false,
        fd6BudgetGeneral: false,
        activity: false,
    });
    const reportDateRange = ref('');

    const partyId = computed(() => $props.item.contact_profile_id);
    const party = computed(() => $props.item.party);

    watch(partyId, (newVal) => {
        if (newVal !== null) {
            for(let party of $props.contactProfiles) {
                if (party.id === newVal) {
                    partyDetails.value = party;
                    break;
                }
            }
            let startDate = getFirstDate();
            let endDate = new Date().toISOString().split('T')[0];
            reportDateRange.value = `(${startDate} - ${endDate})`

            let query = '?company_id=' + $route.params.companyId;
            query += "&contact_profile_id=" + newVal
            query += "&start_date=" + startDate
            query += "&end_date=" + endDate
            getPartyLedgerSummary({
                query: query
            })
                .then(res => {
                    if (res.data) {
                        partyReportDetails.value = res.data;
                    }

                })
        }
    })

    watch(party, (newVal) => {
        if (newVal == null) {
          return $props.item.contact_profile_id = null
        }
        return $props.item.contact_profile_id = newVal.id
    });

    const onChangeSelectedProject = () => {
        if (!selectedProject.value) {
            deselectedProject();
            return;
        }

        $props.item.project_id = selectedProject.value
        budgetMasterDropdown.value.clear();
    }

    const onChangeSelectedBudget = () => {
        if (selectedBudgetMaster.value) {
            selectedDonor.value = selectedBudgetMaster.value;
            $props.item.budget_master_id = selectedBudgetMaster.value.id;
            $props.item.donor_id = selectedBudgetMaster.value.donor_id;
            $props.item.project_account_id = null;
            $props.item.budget_general_id = null;
            selectedActivity.value = null;
            selectedActivityDetail.value = {};
            budget.value = 0;
            activityDropdown.value.clear();
        }

        if (selectedBudgetMaster.value === null) {
            deselectedBudgetMaster()
        }
    };

    const onSelectFd6BudgetMaster = () => {

        let found = false;
        const newVal = selectedFd6BudgetMaster.value;

        $props.fd6Donors.map(donor => {
            if (donor.id === newVal) {
                $props.item.fd6_budget_master_id = donor.id;
                $props.item.fd6_budget_general_id = null;
                getFd6BudgetGeneral(newVal);
                found = true;
            }
        });

        if (newVal === null || !found) {
            deselectedFd6BudgetMaster()
        }
    };

    const onChangeActivityHead = () => {
        const activity = selectedActivity.value;
        if (activity) {
            $props.item.budget_general_id = activity.budget_general_id;
            $props.item.project_account_id = activity.id;
            $props.item.account_head_id = activity.account_head;
            $props.item.fd6_account_id = activity.fd6_account_id;
            $props.item.program_id = activity.program_id;
            originalBudget = (typeof activity.budget_amount !== 'undefined') ? activity.budget_amount : 0;
            selectedActivityDetail.value = activity;
            getActualCost(activity);
        }

        if (activity === null) {
            deselectedActivity()
        }
    };

    const getFirstDate = (interval = 0) => {
        const date = new Date();
        return new Date(date.getFullYear() - 1, date.getMonth(), 2).toISOString().split('T')[0];
    }

    const deselectedProject = () => {
      selectedBudgetMaster.value = null;
      $props.item.project_id = null
      donors.value = []

      deselectedBudgetMaster()
    }

    const deselectedBudgetMaster = () => {
      selectedDonor.value = {};
      $props.item.budget_master_id =null;
      $props.item.donor_id = null;
      selectedActivity.value = null;

      deselectedActivity()
    }

    const deselectedFd6BudgetMaster = () => {
      $props.item.fd6_budget_master_id =null;
      $props.item.fd6_budget_general_id = null;
    }

    const deselectedActivity = () => {
      $props.item.budget_general_id =null;
      $props.item.project_account_id =null;
      $props.item.account_head_id =null;
      $props.item.fd6_account_id =null;
      $props.item.fd6_budget_master_id =null;
      $props.item.fd6_budget_general_id =null;
      $props.item.program_id =null;
      originalBudget = 0;
      selectedActivityDetail.value = null;
      actualCostDetails.value = {};
      budget.value = 0
    }

    const getFd6BudgetGeneral = async (fd6BudgetMasterId) => {
      $emit('isLoading');
      let companyQuery = '?company_id=' + $route.params.companyId+ `&fd6_account_id=${$props.item.fd6_account_id}`;
      try {
        let res = await fetchFd6BudgetGeneral(fd6BudgetMasterId, companyQuery)
        if (res.status) {
          $props.item.fd6_budget_general_id = res.data.id
        }

      } catch (err) {
        if (!err.response) {
          showError('Something is wrong. Check your connectivity!!')
          return;
        }

        if (err.response) {
          showError(err.response?.data.message)
        }
      }
      $emit('loaded');
    }

    const checkLimit = () => {
        if (selectedActivity.value != null && $props.item.debit > budget.value)
          confirm('Amount is greater than budget value. Do you want to continue?')
    }

    const onChangeDebit = () => {
        $props.item.tds_rate_id = null;
        $props.item.vds_rate_id = null;
        $props.item.tds_amount = 0;
        $props.item.vds_amount = 0;
        $props.item.vat_amount = 0;
        $props.item.rate = 0;
        $props.item.quantity = 1;
        $props.item.discount_amount = 0;
        $props.item.net_payable_amount = 0;
        $props.item.total_amount = 0;
        $props.item.is_vat_added = false;
        $props.item.is_set_product = false;
        $props.item.tds_account_head_id = null;
        $props.item.vds_account_head_id = null;
    }

    const getActualCost = async (activity) => {
        if (activity.id != null){
            $emit('isLoading');
            actualCostDetails.value = {};
            let companyQuery = `?company_id=${$route.params.companyId}&range=${activity?.budget_range}`;

            if (selectedDonor.value.party) {
              companyQuery += `&donor_id=${selectedDonor.value.party}`;
            }
            if (selectedDonor.value.cost_centre_id) {
              companyQuery += `&cost_centre_id=${selectedDonor.value.cost_centre_id}`;
            }
            try {
                let res = await fetchActualCost(activity.id, companyQuery)
                if (res.status) {
                    actualCostDetails.value = res.data;
                    budget.value = originalBudget - res.data.total;
                    budget.value += $props.item?.old_debit ?? 0
                }
            } catch (err) {
                if (!err.response) {
                    showError('Something is wrong. Check your connectivity!!')
                }
                if (err.response) {
                    showError(err.response?.data.message)
                }
            }
            $emit('loaded');
        } else {
            budget.value = originalBudget
        }
    }

    const showActualDataModal = async () => {
      if (selectedActivity.value === null) {
        showError("Please select a Activity Head first");
        return;
      }
      if (actualBudgetModal.value !== null)
        actualBudgetModal.value.toggleModal();
    }

    const showPartyModal = async () => {
      if ($props.item.contact_profile_id === null) {
        showError("Please select a Party first");
        return;
      }
      if (partyReportModal.value !== null)
        partyReportModal.value.toggleModal();
    }

    onMounted(() => {
        if ($props.item.project_id) {
            selectedProject.value = $props.item.project_id;
        }

        firstLoad.value = {
            budgetMaster: !!$props.item.budget_master_id,
            fd6BudgetGeneral: !!$props.item.fd6_budget_master_id,
            activity: !!$props.item.account_head_id,
        }

        if ($props.item.budget_master_id) {
            selectedBudgetMaster.value = {
                ...$props.item.budget,
                donor_id: $props.item.donor_id,
            };
            selectedActivity.value = $props.item.activity
            selectedFd6BudgetMaster.value = $props.item.fd6_budget_master_id;
        }
    });
</script>
<style scoped>
.journal-line{
  border: 1px solid #f0f2f5;
}
.journal-line:hover{
  background: #f0f2f5;
}
.tds-vds-label {
    font-size: 16px;
    font-weight: 500;
}
.card-body {
  padding: .5rem;
}
.no-dots {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
</style>
